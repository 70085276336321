html {
    font-size: 16px;


}

@media (max-width: 1440px) {
    html {
      font-size: 13px;
    }
  }
@media (max-width: 1040px) {
    html {
      font-size: 10px;
    }
  }